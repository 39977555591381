import Header from "../public/Header";
const PaymentSuccess = () => {

    return <> <Header />
        <section>
            <div class="container">
                <div class="row">
                    <h2 class="custom_heading_payment_success"> Thank you for subscribing India's fastest growing ERP ("The School World") application. You will recieve an email with all the details shortly.</h2>
                </div>
            </div>
        </section>
    </>

}

export default PaymentSuccess;