import "./App.css";
import About from "./components/public/About";
import CopyRight from "./components/public/CopyRight";
import FooterInfo from "./components/public/FooterInfo";
import LandingPage from "./components/public/LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WhatWeDo from "./components/public/WhatWeDo";
import Contact from "./components/public/Contact";
import Portfolio from "./components/public/Portfolio";
import Header from "./components/public/Header";
import TermsAndConditions from "./components/Policies/Terms";
import PrivacyPolicy from "./components/Policies/Privacy";
import PaymentTermsAndConditions from "./components/Policies/EPayTerms";
import CancellationPolicy from "./components/Policies/Cancellation";
import RefundPolicy from "./components/Policies/Refund";
import Porducts from "./components/public/Products";
import { Checkout } from "./components/subscriptions/Checkout";
import PaymentSuccess from "./components/subscriptions/PaymentSuccess";
import PaymentFailure from "./components/subscriptions/PaymentFailure";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/home" element={<LandingPage />}></Route>
        <Route exact path="/about" element={<About />}></Route>
        <Route exact path="/services" element={<WhatWeDo />}></Route>
        <Route exact path="/products" element={<Porducts />}></Route>
        <Route exact path="/contactus" element={<Contact />}></Route>
        <Route exact path="/portfolio" element={<Portfolio />}></Route>
        <Route exact path="/terms-of-service" element={<TermsAndConditions />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/epay-tnc" element={<PaymentTermsAndConditions />}></Route>
        <Route exact path="/cancellation-policy" element={<CancellationPolicy />}></Route>
        <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
        <Route exact path="/subscriptions/:planId/checkout" element={<Checkout />}></Route>
        <Route exact path="/subscriptions/:planId/success" element={<PaymentSuccess />}></Route>
        <Route exact path="/subscriptions/:planId/failed" element={<PaymentFailure />}></Route>
        <Route exact path="/" element={<LandingPage />}></Route>
      </Routes>
      <FooterInfo />
      <CopyRight />
    </Router>
  );
}

export default App;
