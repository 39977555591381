import PolicyTemplate from "./Template";

const CancellationPolicy = () => {
    const policies = [
        { title: "", description: "Users need to ensure that the information submitted by them is correct and true to the knowledge in all aspects." },
        { title: "", description: "In case any information is found to be incorrect or false at a later date, Edutoj Technology Private Limited shall have the right to cancel the subscription/invoice of any kind." },
        { title: "", description: "In the event of the cancellation of application of any kind of Subscription or renewal or invoice due to furnishing of incorrect/false information, the amount paid by the member will not be refunded except as decided by the management of Edutoj Technology Private Limited." },
        { title: "", description: "Policies Designed by Edutoj Technology Private Limited." },
        { title: "", description: "All Policies and Procedures are governed under the law of Jharkhand." },
        { title: "", description: "Policies Applicable from 02-January-2023." }

    ];
    return <>
        <PolicyTemplate heading={"Cancellation policy"} policies={policies} />
    </>
}

export default CancellationPolicy;