import Header from "./Header";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Portfolio = () => {
  const location = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(false);
  useEffect(() => {
    if (
      window.location.href.indexOf("/home") > 0 ||
      window.location.href.lastIndexOf("/") + 1 === window.location.href.length
    ) {
      setIsLandingPage(true);
    } else {
      setIsLandingPage(false);
    }
  }, [location]);
  return (
    <>
      {isLandingPage === false && <Header />}
      <section class="portfolio_section layout_padding">
        <div class="container">
          <div class="custom_heading-container">
            <h2>Our Portfolio</h2>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-6">
                  <div class="box b-1">
                    <img src="images/p-1.png" alt="" />
                    <h4>Logo Design</h4>
                  </div>
                  <div class="box b-2">
                    <img src="images/p-2.png" alt="" />
                    <h4>Mobile App Development</h4>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="box b-3">
                    <img src="images/cloud.jpg" alt="" />
                    <h4>Cloud & Infra Management</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-sm-6">
                  <div class="box b-4">
                    <img src="images/p-2.png" alt="" />
                    <h4>Web App Development</h4>
                  </div>
                  <div class="box b-5">
                    <img src="images/p-5.png" alt="" />
                    <h4>Website Design</h4>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="box b-6">
                    <img src="images/erp.png" alt="" />
                    <h4>Specialized in school ERP</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
