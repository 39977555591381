import PolicyTemplate from "./Template"


const TermsAndConditions = () => {

    const policies = [
        { title: "Terms", description: "By accessing our Edutoj Technology Private Limited website you are agreeing to be bound by these terms of service, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Edutoj Technology Private Limited website. The materials contained in the website are protected by applicable copyright and trademark law." },
        {
            title: "Use License", description:
                [
                    {
                        title: "Permission is granted temporarily for non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:",
                        childrens: [
                            "modify or copy the materials;",
                            "use the materials for any commercial purpose, or for any public display (commercial or non-commercial);",
                            "attempt to decompile or reverse engineer any software contained in Edutoj Technology Private Limited;",
                            "remove any copyright or other proprietary notations from the materials; or",
                            "transfer the materials to another person or “mirror” the materials on any other server."
                        ]
                    },
                    {
                        title: "This license shall automatically terminate if you violate any of these restrictions and may be terminated by Edutoj Technology Private Limited at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format."
                    }
                ]
        },
        {
            title: "Disclaimer", description:
                [
                    {
                        title: "The materials within Edutoj Technology Private Limited are provided on an ‘as-is’ basis. Edutoj Technology Private Limited makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.",
                    },
                    {
                        title: "Further, Edutoj Technology Private Limited does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Edutoj Technology Private Limited website."
                    }
                ]
        },
        { title: "Limitations", description: "In no event shall Edutoj Technology Private Limited or its suppliers be liable for any damages(including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Edutoj Technology Private Limited website, even if Edutoj Technology Private Limited or a Edutoj Technology Private Limited authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you." },
        { title: "Accuracy of materials", description: "The materials appearing on the Edutoj Technology Private Limited Website could include technical, typographical, or photographic errors. Edutoj Technology Private Limited does not warrant that any of the materials on the Edutoj Technology Private Limited Website are accurate, complete, or current. Edutoj Technology Private Limited may make changes to the materials contained in Edutoj Technology Private Limited Website at any time without notice." },
        { title: "Links", description: "Edutoj Technology Private Limited has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Edutoj Technology Private Limited of the site. Use of any such linked website is at the user’s own risk." },
        { title: "Modifications", description: "Edutoj Technology Private Limited may revise these terms of service for its app at any time without notice. By using Edutoj Technology Private Limited Website you are agreeing to be bound by the then current version of these terms of service." },
        { title: "Governing law", description: "These terms and conditions are governed by and construed in accordance with the laws of Jharkhand and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location." },

    ]
    return <>
        <PolicyTemplate heading={"Terms of service"} policies={policies} />

    </>
}

export default TermsAndConditions