import * as actiontypes from "../actions/ActionTypes";

function paymentReducer(state = {}, action) {
    switch (action.type) {
        case actiontypes.PROCESS_MARCHANT_ORDER: {
            return action.response;
        }
        default:
            return state;
    }
}

export { paymentReducer };