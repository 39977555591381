import CustomMenu from "./CustomMenu";

const Header = () => {
  return (
    <div className="sub_page">
      <div class="hero_area">
        <header class="header_section">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-lg custom_nav-container pt-3">
              <a class="navbar-brand" href="/">
                <img src="/images/edutoj-logo.png" alt="" />
                <span className="brand_padding">Edutoj Technology</span>
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto mr-2">
                  <li class="nav-item active">
                    <a class="nav-link" href="/">
                      Home <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/about">
                      About{" "}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/portfolio">
                      Portfolio{" "}
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/services">
                      Services
                    </a>
                  </li>
                  <li class="nav-item">
                    <a className="nav-link" href={"/products"}>
                      Products
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/contactus">
                      Contact us
                    </a>
                  </li>
                </ul>
                <div class="user_option">
                  <div class="login_btn-container">
                    <a href="">Login</a>
                  </div>
                  <form class="form-inline my-2 my-lg-0">
                    <button
                      class="btn  my-2 my-sm-0 nav_search-btn"
                      type="submit"
                    ></button>
                  </form>
                </div>
              </div>
              <div class="call_btn">
                <a href="">Call: 0326 7968688</a>
              </div>
            </nav>
          </div>
        </header>
        <CustomMenu />
      </div>
    </div>
  );
};

export default Header;
