const CopyRight = () => {
  return (
    <footer class="container-fluid footer_section">
      <p>
        &copy; 2023 - {new Date().getFullYear()} All Rights Reserved By
        <a href="https://edutoj.com">&nbsp;Edutoj Technology</a>
      </p>
    </footer>
  );
};

export default CopyRight;
