import PolicyTemplate from "./Template";

const RefundPolicy = () => {
    const policies = [
        { title: "", description: "No Refund of the Subscription/Invoice amount will be made by Edutoj Technology Private Limited, once the subscription and payment is satisfactorily accepted." },
        { title: "", description: "It is the responsibility of people to understand the requirements of the Edutoj Technology Private Limited Subscription and Payment Facility, before deciding to avail the same." },
        { title: "", description: "In the event of the cancellation of any kind of Subscription or renewal or invoice due to furnishing of incorrect/false information, the amount paid by the member will not be refunded except as decided by the management of Edutoj Technology Private Limited." },
        { title: "", description: "Policies Designed by Edutoj Technology Private Limited." },
        { title: "", description: "All Policies and Procedures are governed under the law of Jharkhand." },
        { title: "", description: "Policies Applicable from 02-January-2023." }

    ];
    return <>
        <PolicyTemplate heading={"Refund policy"} policies={policies} />
    </>
}

export default RefundPolicy;