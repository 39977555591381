import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "../middlewares/apiMiddleware";
import * as paymentReducers from "../reducers/PaymentReducer";
import { subscriptionPlan } from "../reducers/SubscriptionPlanReducer";

export default configureStore({
  reducer: {
    paymentResponse: paymentReducers.paymentReducer,
    subscriptionPlans: subscriptionPlan
  },
  middleware: [apiMiddleware],
});
