import Header from "../public/Header";

const PaymentFailure = () => {

    return <>
        <Header />
        <section>
            <div class="container">
                <div class="row">
                    <h2 className="custom_heading_payment_failed"> We could not verify your payment information. If your amount got duducted it will be refunded automatically to you payment source. Thank you!!</h2>
                </div>
            </div>
        </section>
    </>

}

export default PaymentFailure;