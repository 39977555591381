import { useEffect } from "react";
import { useState } from "react";

const FooterInfo = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [subscribeText, setSubscribeText] = useState("");

  useEffect(() => {
    setSuccessMessage("");
  }, []);
  const subscribeHandler = () => {
    if (subscribeText.length <= 0) {
      setTimeout(() => {
        setSuccessMessage("");
        setMessageColor("#0CAA7D");
      }, 5000);
      setSuccessMessage("Enter a valid email.");
      setMessageColor("red");
    } else {
      setSuccessMessage("Thank You for Subscribing.");
      setMessageColor("#0CAA7D");
    }
  };

  return (
    <section class="info_section layout_padding-top layout_padding2-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="info_links pl-lg-5">
              <h4>Policies</h4>
              <ul>
                <li class="">
                  <a href="/terms-of-service"> Terms of service </a>
                </li>
                <li>
                  <a href="/privacy-policy"> Privacy policy </a>
                </li>
                <li>
                  <a class="" href="/epay-tnc">
                    E-Payments terms and condition
                  </a>
                </li>
                <li>
                  <a class="" href="/cancellation-policy">
                    Cancellation policy
                  </a>
                </li>
                <li>
                  <a href="/refund-policy"> Refund policy </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="info_contact">
              <h4>Location</h4>
              <div>
                <img src="images/location.png" alt="" />
                <p>915 Thakurkulhi, Dhaiya, Dhanbad - 826004</p>
              </div>
              <div>
                <img src="images/telephone.png" alt="" />
                <p>( 0326 7968688 )</p>
              </div>
              <div>
                <img src="images/envelope.png" alt="" />
                <p>contact@edutoj.com</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="info_social">
              <h4>Find us on</h4>
              <div class="social_container">
                <div>
                  <a href="https://www.facebook.com/edutojtech">
                    <img src="images/facebook-logo.png" alt="" />
                  </a>
                </div>
                {/* <div>
                  <a href="">
                    <img src="images/twitter-logo.png" alt="" />
                  </a>
                </div> */}
                <div>
                  <a href="https://www.instagram.com/edutojtech/">
                    <img src="images/instagram.png" alt="" />
                  </a>
                </div>
                <div>
                  <a href="https://www.linkedin.com/company/edutojtechnology/">
                    <img src="images/linkedin-sign.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="info_form">
              <h4>
                Newsletter{" "}
                <span style={{ fontSize: "1rem", color: messageColor }}>
                  {successMessage}
                </span>
              </h4>

              <input
                type="text"
                placeholder="Enter Your Email"
                onChange={(e) => {
                  setSubscribeText(e.target.value);
                }}
              />
              <button type="button" onClick={subscribeHandler}>
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterInfo;
