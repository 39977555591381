import Header from "./Header";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const About = () => {
  const location = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(false);
  useEffect(() => {
    if (
      window.location.href.indexOf("/home") > 0 ||
      window.location.href.lastIndexOf("/") + 1 === window.location.href.length
    ) {
      setIsLandingPage(true);
    } else {
      setIsLandingPage(false);
    }
  }, [location]);
  return (
    <>
      {isLandingPage === false && <Header />}
      <section class="about_section layout_padding mt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <div class="img-box">
                <img src="images/about-img.png" alt="" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="detail-box">
                <div class="custom_heading-container">
                  <h2>About Edutoj</h2>
                </div>

                <p>
                  We are a trusted Digital Engineering and Enterprise
                  Modernization partner, combining deep technical expertise and
                  industry experience to help our clients anticipate what’s next
                  and answer questions before they’re asked. Our offerings and
                  proven solutions create unique competitive advantage for our
                  clients.
                </p>
                <div>
                  <a href=""> About More </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
