import { isArray } from "lodash";

const PolicyTemplateItem = ({ index, heading, description }) => {
    return <>
        <div className="row">
            {heading?.length > 0 && <h6 style={{ width: "100%" }}>{`${(index + 1)}.  ${heading}`}</h6>}
            {isArray(description) ?
                <div>
                    <ul style={{ float: "left" }}>{
                        description.map((item => {
                            return isArray(item.childrens) ?
                                <>
                                    <li className="text-justify">{item.title}</li>
                                    <ul>
                                        {item.childrens.map(innerItem => {
                                            return <li className="text-justify">{innerItem}</li>
                                        })}
                                    </ul>

                                </>
                                : <li className="text-justify">{item.title}</li>
                        }
                        ))}
                    </ul>
                </div>
                : <p className="text-justify pl-4"> {description}</p>}
        </div>
    </>
}
export default PolicyTemplateItem;