import Header from "./Header";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Products.css"
const Porducts = () => {

    const location = useLocation();
    const [isLandingPage, setIsLandingPage] = useState(false);
    useEffect(() => {
        if (
            window.location.href.indexOf("/home") > 0 ||
            window.location.href.lastIndexOf("/") + 1 === window.location.href.length
        ) {
            setIsLandingPage(true);
        } else {
            setIsLandingPage(false);
        }
    }, [location]);

    return (
        <>
            {isLandingPage === false && <Header />}
            <div id="pricing-box" className="section wb">
                <div className="container">
                    <div className="custom_heading-container">
                        <h2>Our products</h2>
                    </div>
                </div>
                <section className="skill_section layout_padding2">
                    <div className="skill_padding">
                        <div className="container">
                            <div className="row">
                                <h3 className="product-header">Now you can manage your school, college, or any  educational center with</h3>
                                <span className="product-name">
                                    <Link
                                        target="_blank"
                                        to={"https://theschoolworld.com/"}
                                        title='The school world' >
                                        <h1>"The school world"</h1>

                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container pt-2">
                    <div className="custom_heading-container pb-2">
                        <h2>Features and plan</h2>
                    </div>
                    <div className="row pb-2">
                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable">
                                <div className="pricingTable-header">
                                    <span className="heading">
                                        <h3>Standard</h3>
                                    </span>
                                    <span className="price-value">&#8377; 5000 <span className="month">per month</span></span>
                                    <span className="price-value">&#8377; 55000 <span className="month">per year</span> </span>
                                    <span className="price-desc">Suitable for small schools and new schools having single branch</span>
                                </div>

                                <div className="pricingContent">
                                    <i className="fa fa-adjust"></i>
                                    <ul>
                                        <li>Dashboard
                                            <ul>
                                                <li>Quick actions</li>
                                                <li>Fee summary</li>
                                                <li>Student Summary</li>
                                            </ul>
                                        </li>
                                        <li>Manage fees
                                            <ul>
                                                <li>Admission fees</li>
                                                <li>Tuition fees</li>
                                                <li>Examinitaion fees</li>
                                                <li>Uniform fees</li>
                                                <li>Book fees</li>
                                            </ul>
                                        </li>
                                        <li>Manage Admission
                                            <ul>
                                                <li>Admission form download</li>
                                                <li>Admission by School</li>
                                            </ul>
                                        </li>
                                        <li>Manage Users
                                            <ul>
                                                <li>Edit user profile</li>
                                                <li>Number of users: 5</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="contact_section pricingTable-sign-up">
                                    <Link className="btn-block" to={"/subscriptions/1/checkout"}>Subscribe now</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable pink">
                                <div className="pricingTable-header">
                                    <span className="heading">
                                        <h3>Business</h3>
                                    </span>
                                    <span className="price-value">&#8377; 8000 <span className="month">per month</span></span>
                                    <span className="price-value">&#8377; 90000 <span className="month">per year</span> </span>
                                    <span className="price-desc">Suitable for medium scale schools having single branch</span>
                                </div>

                                <div className="pricingContent">
                                    <i className="fa fa-briefcase"></i>
                                    <ul>
                                        <li>Dashboard
                                            <ul>
                                                <li>Quick actions</li>
                                                <li>Fee summary</li>
                                                <li>Student Summary</li>
                                                <li>Top 5 students with highest due</li>
                                                <li>Fee Collection by class</li>
                                            </ul>
                                        </li>
                                        <li>Manage fees
                                            <ul>
                                                <li>Admission fees</li>
                                                <li>Tuition fees</li>
                                                <li>Examinitaion fees</li>
                                                <li>Uniform fees</li>
                                                <li>Book fees</li>
                                                <li>Late fees</li>
                                                <li>Transport fees</li>
                                                <li>Custody fee</li>
                                            </ul>
                                        </li>
                                        <li>Manage Transport
                                            <ul>
                                                <li>Manage locations</li>
                                                <li>Manage vendors</li>
                                                <li>Manage vendor fees</li>
                                                <li>Manage Student transport</li>
                                            </ul>
                                        </li>
                                        <li>Manage Admission
                                            <ul>
                                                <li>Admission form download</li>
                                                <li>Admission by School</li>
                                            </ul>
                                        </li>
                                        <li>Manage Attendance
                                            <ul>
                                                <li>Student Attendance</li>
                                                <li>Holiday Calendar</li>
                                                <li>Attendance report for school</li>
                                            </ul>
                                        </li>
                                        <li>Manage Users
                                            <ul>
                                                <li>Edit user profile</li>
                                                <li>Create/Modify users</li>
                                                <li>Manage roles</li>
                                                <li>Remove users</li>
                                                <li>Number of users: 20</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact_section pricingTable-sign-up">
                                    <Link className="btn-block" to={"/subscriptions/2/checkout"}>Subscribe now</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="pricingTable orange">
                                <div className="pricingTable-header">
                                    <span className="heading">
                                        <h3>Premium</h3>
                                    </span>
                                    <span className="price-value">&#8377; 15000 <span className="month">per month</span></span>
                                    <span className="price-value">&#8377; 150000 <span className="month">per year</span> </span>
                                    <span className="price-desc">Suitable for large scale orgnaisations with multiple branches</span>
                                </div>

                                <div className="pricingContent">
                                    <i className="fa fa-cube"></i>
                                    <ul>
                                        <li>Dashboard
                                            <ul>
                                                <li>Quick actions</li>
                                                <li>Fee summary</li>
                                                <li>Student Summary</li>
                                                <li>Top 5 students with highest due</li>
                                                <li>Fee Collection by class</li>
                                                <li>Graphical view for fee type</li>
                                                <li>Monthly Fee collection trend</li>
                                                <li>Income and expense summary</li>
                                                <li>Social media tracker</li>
                                                <li>Inventory summary</li>
                                            </ul>
                                        </li>
                                        <li>Manage fees
                                            <ul>
                                                <li>Admission fees</li>
                                                <li>Tuition fees</li>
                                                <li>Examinitaion fees</li>
                                                <li>Uniform fees</li>
                                                <li>Book fees</li>
                                                <li>Late fees</li>
                                                <li>Transport fees</li>
                                                <li>Custody fee</li>
                                            </ul>
                                        </li>
                                        <li>Manage Transport
                                            <ul>
                                                <li>Manage locations</li>
                                                <li>Manage vendors</li>
                                                <li>Manage vendor fees</li>
                                                <li>Manage Student transport</li>
                                            </ul>
                                        </li>
                                        <li>Manage Admission
                                            <ul>
                                                <li>Admission form download</li>
                                                <li>Admission by School</li>
                                                <li>Online Admission</li>
                                            </ul>
                                        </li>
                                        <li>Manage Attendance
                                            <ul>
                                                <li>Student Attendance</li>
                                                <li>Holiday Calendar</li>
                                                <li>Attendance report for school</li>
                                                <li>Teacher Attendance</li>
                                                <li>Attendance report for students/parents</li>
                                            </ul>
                                        </li>
                                        <li>Manage Users
                                            <ul>
                                                <li>Edit user profile</li>
                                                <li>Create/Modify users</li>
                                                <li>Manage roles</li>
                                                <li>Remove users</li>
                                                <li>Number of users: Unlimited</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>

                                <div className="contact_section pricingTable-sign-up">
                                    <Link className="btn-block" to={"/subscriptions/3/checkout"}>Subscribe now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>)
}

export default Porducts;