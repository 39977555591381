import PolicyTemplateItem from "./TemplateItem"
import { useLocation } from "react-router-dom";
import Header from "../public/Header"
import { useEffect, useState } from "react";

const PolicyTemplate = ({ heading, policies = [] }) => {
    const location = useLocation();
    const [isLandingPage, setIsLandingPage] = useState(false);
    useEffect(() => {
        if (
            window.location.href.indexOf("/home") > 0 ||
            window.location.href.lastIndexOf("/") + 1 === window.location.href.length
        ) {
            setIsLandingPage(true);
        } else {
            setIsLandingPage(false);
        }
    }, [location]);

    return <>
        {isLandingPage === false && <Header />}
        <div className="container">
            <div className="row">
                <h4 className="text-center" style={{ width: "100%", marginTop: "1rem", color: "red" }}>{heading}</h4>
            </div>
            {policies.map((item, index) => {
                return <PolicyTemplateItem index={index} heading={item.title} description={item.description} />
            })}
        </div>
    </>
}
export default PolicyTemplate;