import { useNavigate, useParams } from "react-router-dom"
import Header from "../public/Header"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { completeMarchantOrder, processMarchantOrder } from "../../actions/payments/PaymentActions";

export const Checkout = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [amount, setAmount] = useState(0);

    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const plans = useSelector(store => store.subscriptionPlans);
    const filteredPlan = plans.filter(p => p.planId === parseInt(params?.planId));
    const selectedPlan = filteredPlan?.length > 0 ? filteredPlan[0] : {};
    const paymentResponse = useSelector(store => store.paymentResponse);
    const commonUrl = "https://etosmsdev1.azurewebsites.net/api/v1/payments"; //"https://localhost:7289/api/v1/payments";

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const handlePayment = (response) => {

        const options = {
            key: response.gatewayKey, // Enter the Key ID generated from the Dashboard
            amount: response.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: response.currency,
            name: "Edutoj Technology Private Limited",
            description: "You will be subscribe to selected plan for The School World ERP application",
            order_id: response.orderId,
            image: "https://edutoj.com/images/edutoj-logo.png",
            handler: function (razorPayResponse) {
                dispatch(completeMarchantOrder(commonUrl + "/" + razorPayResponse.razorpay_payment_id, razorPayResponse.razorpay_order_id))
                    .then((res) => {
                        if (res?.toLowerCase() === "captured") {
                            navigate("/subscriptions/" + params?.planId + "/success");
                        }
                        else {
                            navigate("/subscriptions/" + params?.planId + "/failed");
                        }
                    });
            },
            prefill: {
                name: response.name,
                email: response.email,
                contact: response.phone,
            },
        }
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const checkoutHandler = (event) => {
        event.preventDefault();


        let data = {
            "name": name,
            "address": address,
            "amount": amount,
            "email": email,
            "phone": phone
        }

        dispatch(processMarchantOrder(commonUrl, data))
            .then((res) => { handlePayment(res) })
            .catch((er) => { });
    }

    const handleSubscriptionTypeChange = (event, type) => {
        let selectedType = selectedPlan.subscriptionTypes?.filter(st => st.type === type);
        if (selectedType?.length > 0) {
            setAmount(selectedType[0].amount);
        }
    }

    return <>
        <Header />
        <section class="contact_section">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6 px-0">
                        <div class="img-box">
                            {/* <h5>Great you have taken your first step to grow your business.</h5> */}
                            <img src="/images/contact.jpg" alt="" class="w-100" />
                        </div>
                    </div>
                    <div class="col-lg-4 offset-lg-2 col-md-5 offset-md-1">
                        <h2 class="custom_heading">Please provide few details</h2>
                        <form onSubmit={checkoutHandler}>
                            <div>
                                <div className="row">
                                    <label style={{ fontSize: "1.2rem" }} className="ml-2">Select subscription type</label>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input type="radio" name="subscriptionType" onChange={(event) => handleSubscriptionTypeChange(event, "Monthly")} />
                                        <label style={{ fontSize: "1.2rem" }} className="ml-2">Monthly</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input type="radio" name="subscriptionType" onChange={(event) => handleSubscriptionTypeChange(event, "Yearly")} />
                                        <label style={{ fontSize: "1.2rem" }} className="ml-2" > Yearly</label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <input type="text" placeholder="Name" required onChange={(event) => setName(event.target.value)} />
                            </div>
                            <div>
                                <input type="email" placeholder="Email" required onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            <div>
                                <input type="text" placeholder="Pone Number" required onChange={(event) => setPhone(event.target.value)} />
                            </div>
                            <div>
                                <input type="text" placeholder="Address" onChange={(event) => setAddress(event.target.value)} />
                            </div>
                            <div>
                                <input value={amount} disabled type="number" placeholder="Amount" required />
                            </div>
                            <div class="d-flex mt-4">
                                <button >Proceed to payment</button>
                            </div>
                        </form>


                    </div>

                </div>
            </div >
        </section >
    </>
}
