import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Portfolio from "./Portfolio";
import Skills from "./Skills";
import Testimonial from "./Testimonial";
import WhatWeDo from "./WhatWeDo";

const LandingPage = () => {
  return (
    <>
      <Home />
      <About />
      <WhatWeDo />
      <Portfolio />
      {/* <Testimonial /> */}
      <Contact />
    </>
  );
};

export default LandingPage;
