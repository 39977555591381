const initialstate = [
    {
        planId: 1,
        planName: "Standard",
        subscriptionTypes: [
            { type: "Monthly", amount: 5000 },
            { type: "Yearly", amount: 55000 }
        ]
    },
    {
        planId: 2,
        planName: "Business",
        subscriptionTypes: [
            { type: "Monthly", amount: 8000 },
            { type: "Yearly", amount: 90000 }
        ]
    },
    {
        planId: 3,
        planName: "Premium",
        subscriptionTypes: [
            { type: "Monthly", amount: 15000 },
            { type: "Yearly", amount: 150000 }
        ]
    }
];

function subscriptionPlan(state = initialstate, action) {
    switch (action.type) {
        case "GET_SUBSCRIPTION_PLAN":
            return state;
        default:
            return state;
    }
}

export { subscriptionPlan }