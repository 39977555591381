import * as actionTypes from '../ActionTypes';

const processMarchantOrder = (url, data) => {
    return {
        type: actionTypes.PROCESS_MARCHANT_ORDER,
        resolve: true,
        meta: {
            api: {
                method: "post",
                url: url,
                data: data
            },
        },
    }
}

const completeMarchantOrder = (url, data) => {
    return {
        type: actionTypes.COMPLETE_MARCHANT_ORDER,
        resolve: true,
        meta: {
            api: {
                method: "patch",
                url: url,
                data: data
            },
        },
    }
}

export { processMarchantOrder, completeMarchantOrder }