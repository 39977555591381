import Header from "./Header";
import Skills from "./Skills";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const WhatWeDo = () => {
  const location = useLocation();
  const [isLandingPage, setIsLandingPage] = useState(false);
  useEffect(() => {
    if (
      window.location.href.indexOf("/home") > 0 ||
      window.location.href.lastIndexOf("/") + 1 === window.location.href.length
    ) {
      setIsLandingPage(true);
    } else {
      setIsLandingPage(false);
    }
  }, [location]);
  return (
    <>
      {isLandingPage === false && <Header />}
      <section class="do_section layout_padding-bottom">
        <div class="container">
          <div class="custom_heading-container">
            <h2>What we do</h2>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-6">
              <div class="content-box bg-red">
                <div class="img-box">
                  <img src="images/idea.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Application development and management</h5>
                  <p>
                    With digital acceleration, IT must develop and support
                    modern applications to deliver successful business outcomes
                    while maintaining legacy applications developed over
                    decades.
                  </p>
                  <p>
                    We accelerate application development using
                    containerization, microservices, cloud-native development,
                    DevSecOps, to name a few.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="content-box bg-green">
                <div class="img-box">
                  <img src="images/controller.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Cloud & Infrastructure</h5>
                  <p>
                    Cloud is arguably the most important component of your
                    digital journey. We help quickly create, launch, and deliver
                    your multi-cloud strategy, infrastructure modernization, and
                    digital workplace solutions. We incorporate the best of
                    major cloud and infrastructure providers into a seamless
                    experience for your customers, employees, and other
                    stakeholders.
                  </p>
                  <p>
                    We reduce complexity, costs, and operational challenges
                    while modernizing.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="content-box bg-red">
                <div class="img-box">
                  <img src="images/monitor.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Software Product Engineering</h5>
                  <p>
                    For today’s software businesses, the need to innovate never
                    stops. This has increased the complexity of engineering
                    software products.
                  </p>
                  <p>
                    Our software product engineering services reach across every
                    stage of the software development life cycle to help
                    accelerate software engineering and drive software
                    modernization initiatives to transform your product
                    experiences.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="content-box bg-green">
                <div class="img-box">
                  <img src="images/rocket-ship.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>Intelligent Automation</h5>
                  <p>
                    Intelligent automation helps businesses enhance customer
                    experience, improve employee productivity, and meet the
                    changing needs of the industry.
                  </p>
                  <p>
                    Edutoj leverages its intelligent automation expertise,
                    multi-industry knowledge and its strong low-code platform
                    alliances to build high impact, future-proof enterprise
                    grade applications in weeks, not months and years.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Skills />
    </>
  );
};

export default WhatWeDo;
