import PolicyTemplate from "./Template";

const PaymentTermsAndConditions = () => {
    const policies = [
        { title: "", description: "Please read these terms carefully before using the online payment facility." },
        { title: "", description: "Using the online payment facility on Edutoj Technology Private Limited Website indicates that you accept these terms. If you do not accept these terms do not use this facility." },
        { title: "", description: "All payments are subject to the following conditions:-" },
        { title: "", description: "The description of registration/invoice are specific to you ( i.e. Name, Location and Date etc.) when you log in and register for the services, normally payment is required to be completed (before completing the registration)." },
        { title: "", description: "All fees are quoted in Indian Rupees (INR)." },
        { title: "", description: "Edutoj Technology Private Limited reserves the right to change the fees at any time." },
        { title: "", description: "Your Payment will normally reach the Edutoj Technology Private Limited account to which you are making a payment within two working days." },
        { title: "", description: "If the card supplier declines payment Edutoj Technology Private Limited is under no obligation to bring this fact to your attention. You should check with your bank/credit/debit card supplier that payment has been deducted from your account." },
        { title: "", description: "Policies Designed by Edutoj Technology Private Limited." },
        { title: "", description: "All Policies and Procedures are governed under the law of Jharkhand." },
        { title: "", description: "Policies Applicable from 02-January-2023." }

    ];
    return <>
        <PolicyTemplate heading={"E-Payments terms and conditions"} policies={policies} />
    </>
}

export default PaymentTermsAndConditions;