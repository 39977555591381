import "./Skills.css";
import React from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import ProgressProvider from "../common/ProgressProvider";
// import "react-circular-progressbar/dist/styles.css";

const Skills = () => {
  return (
    <section class="skill_section layout_padding2">
      <div class="container">
        <div class="custom_heading-container">
          <h2>Our Skills</h2>
        </div>
        <div class="skill_padding">
          <div class="row">
            <div class="col-md-3 col-sm-6">
              <div class="box">
                <ProgressProvider valueStart={10} valueEnd={97}>
                  {(value) => (
                    <CircularProgressbar
                      value={value}
                      text={`${value}%`}
                      strokeWidth={2}
                    />
                  )}
                </ProgressProvider>

                <h6>.Net</h6>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="box">
                <ProgressProvider valueStart={10} valueEnd={95}>
                  {(value) => (
                    <CircularProgressbar
                      value={value}
                      text={`${value}%`}
                      strokeWidth={2}
                    />
                  )}
                </ProgressProvider>
                <h6>Cloud (Azure, AWS, GCP)</h6>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="box">
                <ProgressProvider valueStart={10} valueEnd={93}>
                  {(value) => (
                    <CircularProgressbar
                      value={value}
                      text={`${value}%`}
                      strokeWidth={2}
                    />
                  )}
                </ProgressProvider>
                <h6>React Native, Flutter, Kotlin</h6>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class="box">
                <ProgressProvider valueStart={10} valueEnd={76}>
                  {(value) => (
                    <CircularProgressbar
                      value={value}
                      text={`${value}%`}
                      strokeWidth={2}
                    />
                  )}
                </ProgressProvider>
                <h6>ML and AI</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
